import React from 'react';
import { Icon } from 'components/atoms/icon';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
}

export const Specificationcheckbox: React.FC<Props> = ({ title, ...innerProps }) => {
  return (
    <div className="m-specificationcheckbox">
      <label>
        <input type="checkbox" {...innerProps} />
        <div className="m-specificationcheckbox_inner">
          <Icon iconName="checked-white" />
          {title}
        </div>
      </label>
    </div>
  );
};
