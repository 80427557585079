import React, { useState } from 'react';
import { getPath } from 'lib/pathes';
import { useTranslation } from 'react-i18next';
import { mapModifiers } from 'lib/component';
import { Button } from 'components/atoms/button';
import { Container } from 'components/organisms/grid';
import { Heading } from 'components/molecules/heading';
import { Icon } from 'components/atoms/icon';
import { Image } from 'components/atoms/image';
import { Link } from 'components/utils/link';
import { Row, Col } from 'components/organisms/grid';
import { Text } from 'components/atoms/text';

interface ComparisonItemProps {
  imgSrc: string;
  title: string;
  description: string;
  onDelete: () => void;
}

const ComparisonbarItem: React.FC<ComparisonItemProps> = props => {
  return (
    <div className="o-comparisonbar_item">
      <div className="o-comparisonbar_wrap">
        <div className="o-comparisonbar_image">
          <Image src={props.imgSrc} alt={props.title} modifiers="transparent" aspectRatio="comparisonbar" />
        </div>
        <div className="o-comparisonbar_wrapcontent">
          <div className="o-comparisonbar_title">
            <Text modifiers={['medium', 'cerulean']}>{props.title} </Text>
          </div>
          <div className="o-comparisonbar_description">
            <Text modifiers="medium">{props.description}</Text>
          </div>
        </div>
        <div className="o-comparisonbar_close" onClick={props.onDelete}>
          <Icon iconName="close-square" />
        </div>
      </div>
    </div>
  );
};

const ComparisonbarAdd: React.FC = () => {
  return (
    <Link className="o-comparisonbar_item" to={getPath('PRD')}>
      <div className="o-comparisonbar_wrap">
        <div className="o-comparisonbar_add">
          <Icon modifiers="large" iconName="plus" />
        </div>
      </div>
    </Link>
  );
};

interface ComparisonbarlistProps {
  items: Array<{
    id: number;
    title: string;
    description: string;
    imgSrc: string;
  }>;
  onDelete: (id: number) => void;
  onDeleteAll: () => void;
  onCompare: () => void;
}

export const Comparisonbarlist: React.FC<ComparisonbarlistProps> = props => {
  const { t } = useTranslation();
  const [isCollapsed, setCollapsed] = useState(false);

  return (
    <div className={mapModifiers('o-comparisonbar', isCollapsed && 'collapsed')}>
      <Container>
        <div className="o-comparisonbar_collapse" onClick={() => setCollapsed(!isCollapsed)}>
          <Icon iconName="back-to-bottom" />
        </div>
        <div className="o-comparisonbar_head">
          <Row className="align-items-center">
            <Col sm="6">
              <Heading type="h3" modifiers={['white', 'fwbold']}>
                {t('comparison.compare')} ({props.items.length > 3 ? 3 : props.items.length})
              </Heading>
            </Col>

            <Col className="d-sm-flex align-items-start justify-content-end" sm="6">
              <div className="o-comparisonbar_wraphead">
                <div className="o-comparisonbar_text" onClick={props.onDeleteAll}>
                  <Text modifiers={['medium', 'white']}>{t('comparison.delete-all')}</Text>
                </div>
                <div className="o-comparisonbar_button">
                  <Button onClick={props.onCompare} type="button">
                    {t('comparison.compare')}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="o-comparisonbar_product">
          {props.items.map(
            ({ id, title, description, imgSrc }, index) =>
              index < 3 && (
                <ComparisonbarItem
                  key={id}
                  title={title}
                  description={description}
                  imgSrc={imgSrc}
                  onDelete={() => props.onDelete(id)}
                />
              )
          )}

          {props.items.length < 3 && <ComparisonbarAdd />}
        </div>
      </Container>
    </div>
  );
};
