import { Button } from 'components/atoms/button';
import { Loading } from 'components/atoms/loading';
import { Text } from 'components/atoms/text';
import { Buttoncontainer } from 'components/molecules/buttoncontainer';
import { Heading } from 'components/molecules/heading';
import { Specificationcheckbox } from 'components/molecules/specificationcheckbox';
import { Comparisonbarlist } from 'components/organisms/comparisonbar';
import { Col, Row } from 'components/organisms/grid';
import { Modal } from 'components/organisms/modal';
import { Section } from 'components/organisms/section';
import { Tab, TabList, TabPanel, Tabs } from 'components/organisms/tabs';
import APIPATHES from 'constants/api-pathes';
import { BasePageProps } from 'lib/component';
import { getPath } from 'lib/pathes';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ProductType } from 'services/product';
import { action, useAppSelector } from 'store';
import { PUT_PRODUCT_SELECTED, ProductSelectedType } from 'store/types/comparison';

interface Props extends BasePageProps {
  page: ReactElement;
}

const ProductComparison: React.FC<Props> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentSeries, productsCompare } = useAppSelector(state => state.comparison);
  const [isOpenModalWarning, setOpenModalWarning] = useState<boolean>(false);
  const [isOpenModalSeries, setOpenModalSeries] = useState<boolean>(false);
  const [isOpenModalNotification, setOpenModalNotification] = useState<boolean>(false);
  const [isOpenModalDeleteAll, setOpenModalDeleteAll] = useState<boolean>(false);
  const [productIdDeleted, setProductIdDeleted] = useState<number | null>(null);
  const [productsSelected, setProductsSelected] = useState<ProductSelectedType | undefined>(undefined);
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    setProductsSelected(productsCompare);
  }, [productsCompare]);

  const onChangeSpecificationcheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, product: ProductType, type: string, thumbnail: string) => {
      if (e.target.checked) {
        if (
          !productsSelected?.seriesType ||
          (productsSelected.seriesType && productsSelected.seriesType === type && productsSelected.products.length < 3)
        ) {
          setProductsSelected(prev => ({
            ...prev,
            seriesType: prev?.seriesType || type,
            products: [
              ...(prev?.products || []),
              { product: product, series: { name: currentSeries?.data.name || '', thumbnail: thumbnail } },
            ],
          }));
        } else {
          setOpenModalNotification(true);
          if (productsSelected.seriesType && productsSelected.seriesType !== type) {
            setMessage(t('comparison.message-comparison'));
          } else {
            setMessage(
              `${t('comparison.message-comparison-limit-1')} <br /> ${t('comparison.message-comparison-limit-2')}`
            );
          }
        }
      } else {
        setProductsSelected(prev =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          prev!.products.length === 1
            ? undefined
            : {
                ...prev,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                seriesType: prev!.products.length === 1 ? '' : prev!.seriesType,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                products: prev!.products.filter(item => item.product.id !== product.id),
              }
        );
      }
    },
    // eslint-disable-next-line
    [currentSeries, productsSelected]
  );

  const onTogglePopupSeries = useCallback(
    (isOpen?: boolean, isReset?: boolean) => {
      setOpenModalSeries(typeof isOpen !== 'undefined' ? isOpen : true);
      isReset && setProductsSelected(productsCompare);
    },
    [productsCompare]
  );

  const onSaveProductSelected = useCallback(() => {
    action({ type: PUT_PRODUCT_SELECTED, payload: productsSelected });
    setOpenModalSeries(false);
  }, [productsSelected]);

  const onDeleteProductSelected = useCallback(() => {
    if (!!productIdDeleted && productsCompare) {
      const products = productsCompare.products.filter(item => item.product.id !== productIdDeleted);
      const productsAfterDeleted =
        products.length === 0
          ? undefined
          : {
              seriesType: productsCompare.seriesType,
              products,
            };

      setProductsSelected(productsAfterDeleted);
      action({
        type: PUT_PRODUCT_SELECTED,
        payload: productsAfterDeleted,
      });
      setProductIdDeleted(null);
    }
  }, [productIdDeleted, productsCompare]);

  const onDeleteAll = useCallback(() => {
    setProductsSelected(undefined);
    action({
      type: PUT_PRODUCT_SELECTED,
      payload: undefined,
    });
    setOpenModalDeleteAll(false);
  }, []);

  const productIdsSelected = useMemo(() => productsSelected?.products.map(({ product: { id } }) => id) || [], [
    productsSelected,
  ]);

  const onCompare = useCallback(() => {
    if (productsCompare && productsCompare.products.length > 1) {
      history.push(getPath('CMP'));
    } else {
      setOpenModalWarning(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIdsSelected, productsCompare]);

  const renderProduct = useCallback(
    (params: { product: ProductType; seriesTypeId: string; thumbnail: string; index: number }) => (
      <Col key={params.product.id} className={`u-mt-30 u-mt-sp-15 ${params.index < 3 ? 'mt-md-0' : ''}`} md="4">
        <Specificationcheckbox
          title={params.product.attributes.productAttributeOption.compareValue['2'] + ' BTU/h'}
          checked={productIdsSelected.includes(params.product.id)}
          onChange={e => onChangeSpecificationcheckbox(e, params.product, params.seriesTypeId, params.thumbnail)}
        />
      </Col>
    ),
    [onChangeSpecificationcheckbox, productIdsSelected]
  );

  return (
    <div className="p-marutto">
      {React.cloneElement(props.page, {
        onClickCompare: onTogglePopupSeries,
      })}
      {productsCompare && productsCompare.products.length > 0 && (
        <Comparisonbarlist
          items={
            productsCompare.products.map(({ product: { id, attributes }, series }) => ({
              title: series?.name,
              id,
              description: attributes.productAttributeOption.compareValue['2'] + ' BTU/h',
              imgSrc: `${APIPATHES.STORAGE}${series?.thumbnail}`,
            })) || []
          }
          onDelete={id => setProductIdDeleted(id)}
          onDeleteAll={() => productsCompare && setOpenModalDeleteAll(true)}
          onCompare={onCompare}
        />
      )}

      <Modal isOpen={isOpenModalSeries} closeModal={() => onTogglePopupSeries(false, true)}>
        {!currentSeries ? (
          <Loading />
        ) : (
          currentSeries.success && (
            <Section>
              <Heading type="h2" modifiers={['fwmedium', 'cerulean', 'mediummarginbottom']}>
                {t('comparison.heading-select')}
              </Heading>
              <Heading type="h3" modifiers={['fwmedium', 'mediummarginbottom']}>
                {currentSeries.data.productType.name}
              </Heading>
              <Text>{t('comparison.notificaton-comparison')}</Text>
              {currentSeries.data.recursiveChildren.length > 0 ? (
                <Tabs modifiers="typenews">
                  <TabList autoWidth noDirection>
                    {currentSeries.data.recursiveChildren.map(item => (
                      <Tab key={item.id} title={item.name} />
                    ))}
                  </TabList>
                  {currentSeries.data.recursiveChildren.map(item => (
                    <TabPanel key={item.id}>
                      <Row>
                        {item.products.map((product, index) =>
                          renderProduct({
                            product,
                            seriesTypeId: item.seriesTypeId.toString(),
                            thumbnail: item.thumbnail,
                            index,
                          })
                        )}
                      </Row>
                    </TabPanel>
                  ))}
                </Tabs>
              ) : (
                <Row>
                  {currentSeries.data.products.map((product, index) =>
                    renderProduct({
                      product,
                      seriesTypeId: currentSeries.data.seriesTypeId.toString(),
                      thumbnail: currentSeries.data.thumbnail,
                      index,
                    })
                  )}
                </Row>
              )}
              <Buttoncontainer
                primary={
                  <Button buttonType="round" onClick={onSaveProductSelected}>
                    {t('comparison.choose')}
                  </Button>
                }
              />
            </Section>
          )
        )}
      </Modal>

      <Modal
        isOpen={!!productIdDeleted}
        closeModal={() => setProductIdDeleted(null)}
        style={{ content: { maxWidth: 600, minHeight: 130 } }}
        isClosable={false}
        isCentered
      >
        <Heading type="h3" modifiers={['cerulean', 'nonmargintop', 'fwmedium', 'center']}>
          {t('comparison.delete-confirm')}
        </Heading>
        <Buttoncontainer
          modifiers="nonmargin"
          primary={
            <Button buttonType="round" onClick={onDeleteProductSelected}>
              {t('comparison.confirm')}
            </Button>
          }
        />
      </Modal>

      <Modal
        isOpen={isOpenModalDeleteAll}
        closeModal={() => setOpenModalDeleteAll(false)}
        style={{ content: { maxWidth: 600, minHeight: 130 } }}
        isClosable={false}
        isCentered
      >
        <Heading type="h3" modifiers={['cerulean', 'nonmargintop', 'fwmedium', 'center']}>
          {t('comparison.delete-confirm')}
        </Heading>
        <Buttoncontainer
          modifiers="nonmargin"
          primary={
            <Button buttonType="round" onClick={onDeleteAll}>
              {t('comparison.confirm')}
            </Button>
          }
        />
      </Modal>

      <Modal
        isOpen={isOpenModalNotification}
        closeModal={() => setOpenModalNotification(false)}
        style={{ content: { maxWidth: 600, minHeight: 140 } }}
        isClosable={false}
        isCentered
      >
        <Heading type="h3" modifiers={['cerulean', 'nonmargintop', 'fwmedium', 'center']}>
          <div className="u-original-browser-style-lists" dangerouslySetInnerHTML={{ __html: message }} />
        </Heading>
        <Buttoncontainer
          modifiers="nonmargin"
          primary={
            <Button buttonType="round" onClick={() => setOpenModalNotification(false)}>
              {t('comparison.close')}
            </Button>
          }
        />
      </Modal>

      <Modal
        isOpen={isOpenModalWarning}
        closeModal={() => setOpenModalWarning(false)}
        style={{ content: { maxWidth: 600, minHeight: 140 } }}
        isClosable={false}
        isCentered
      >
        <Heading type="h3" modifiers={['cerulean', 'nonmargintop', 'fwmedium', 'center']}>
          <Trans t={t} i18nKey="warning-message" components={{ 1: <br /> }} />
        </Heading>
        <Buttoncontainer
          modifiers="nonmargin"
          primary={
            <Button buttonType="round" onClick={() => setOpenModalWarning(false)}>
              {t('comparison.close')}
            </Button>
          }
        />
      </Modal>
    </div>
  );
};

export default ProductComparison;
