import http from 'services/http';
import { GuideType } from 'services/discovery/guides';
import { SolutionType } from 'services/solution';
import { CaseStudyType } from 'services/discovery/casestudy';
import { BreadcrumbsType, MetaDataType } from 'services/common';

export interface AttributeOption {
  type: number;
  value:
    | string
    | {
        [key: number]: {
          subTitle: string;
          unit: string;
          value: string;
        };
      };
}

interface AttributeType {
  code: string;
  description: string | null;
  type: number;
  name: string;
  productAttributeOption: AttributeOption;
  unit: string;
}

type FeatureBlockCol = {
  image?: string | null;
  title?: string | null;
  content?: string | null;
};

export interface FeatureBlockItem {
  layout: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  col1: FeatureBlockCol;
  col2: FeatureBlockCol;
  col3: FeatureBlockCol;
  title?: string | null;
  image?: string | null;
  imageLarge?: string | null;
  content?: string | null;
  description?: string | null;
}

export type FeatureBlockTemplate = 'default' | 'VRV_A/X_SERIES' | 'VRV_H_SERIES' | 'Marutto';

export interface SeriesType {
  code: string;
  id: number;
  isHiddenHeroBanner?: boolean;
  isNew?: number;
  plattarSceneId?: string;
  thumbnail: string;
  thumbnailHover: string;
  background: string;
  seriesTypeId: number;
  whereToBuy: string;
  whereToBuyText: string;
  buyAtDaikinEShop: string;
  buyAtDaikinEShopText: string;
  subThumbnail: string;
  subBackground: string;
  backgroundMobile: string;
  imageName: string | null;
  imageNameHover: string;
  slider: {
    [x: string]: {
      thumb: string | null;
      youtubeEmbedded?: string;
    };
  };
  relatedSeries: Array<SeriesType>;
  whereToBuyTarget: string;
  buyAtDaikinEShopTarget: string;
  name: string;
  slug: string;
  metaData?: MetaDataType;
  descriptionIcon: Array<string>;
  description: string;
  energyLevel: number | null;
  featureBlocks: { items: { [x: string]: FeatureBlockItem } | FeatureBlockItem[]; template: FeatureBlockTemplate };
  descriptionFeatures: Array<string>;
  seriesType: {
    id: number;
    name: string;
    seriesTypeAttributeSets: Array<{
      id: number;
      name: string;
      setProductAttributes: Array<{
        id: number;
        code: string;
        name: string;
        unit: string;
        description: null;
      }>;
    }>;
  };
  recursiveChildren: Array<SeriesType>;
  products: Array<ProductType>;
  productFeatures: Array<{
    id: number;
    name: string;
    description: string;
    image: string;
    active: number;
  }>;
  guides: Array<GuideType>;
  productType: ProductTypeType;
  caseStudies: Array<CaseStudyType>;
  isUseSpecsByEditor: number;
  specsEditor: string;
  catalogues: Array<{
    id: number;
    name: string;
    file: string;
  }>;
  productCoolings: Array<ProductType>;
  translations: Array<{ locale: string; slug: string | null }>;
  children: Array<{ id: number; slug: string }>;
}

export interface ProductAdvantagePopup {
  title: string | null;
  content: string | null;
}

export interface MdcItem {
  [key: string]: {
    mdcLinkLogo: string | null;
    mdcLogo: string | null;
  };
}

export interface ProductTypeType {
  id: number;
  name: string;
  description: string;
  banner: string;
  bannerMobile: string;
  solutions: Array<SolutionType>;
  guides: Array<GuideType>;
  isCompare: number;
  isFilter: number;
  isMlkk: number;
  mlkkData: {
    banner: string | null;
    bannerMobile: string | null;
    bannerTablet: string | null;
    btnRangeLink: string | null;
    btnRangeText: string | null;
    btnWhereToBuyLink: string | null;
    btnWhereToBuyText: string | null;
    eshopBanner: string | null;
    eshopLink: string | null;
    imageDekstop: string | null;
    imageMobile: string | null;
    imageTablet: string | null;
    imageLink: string | null;
    subTitle: string | null;
    title: string | null;
    ytlink: string | null;
    contactImage: string | null;
    contactTitle: string | null;
    contactBtn: string | null;
    productListTitle: string | null;
    whereToBuyTitle: string | null;
    mdcItem: MdcItem;
    mdcTitle: string | null;
    content?: {
      content?: string | null;
      title?: string | null;
      image?: string | null;
      link?: string | null;
    };
  } | null;
  templateDisplay: number;
  hasChild: number;
  series: Array<SeriesType>;
  slug: string;
  advantages: Array<{
    id: number;
    name: string;
    description: string;
    image: string;
    hasPopup: number;
    popupData: ProductAdvantagePopup | null;
  }>;
  recursiveChildren: Array<ProductTypeType>;
  parent: {
    slug: string;
    name: string;
    translations: Array<{ locale: string; slug: string | null }>;
  };
  translations: Array<{ locale: string; slug: string | null }>;
}

interface GroupType {
  name: string;
  attributes: Array<AttributeType>;
}

export interface ProductType {
  id: number;
  name: string;
  attributes: {
    code: string;
    description: string;
    id: number;
    name: string;
    type: number;
    productAttributeOption: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      compareValue: any;
    };
  };
  group?: Array<GroupType>;
  productAttributes?: {
    name: string;
    group: Array<GroupType>;
  };
  attrHighlight?: {
    name: string;
    group: GroupType;
  };
  series?: SeriesType;
}

interface ProductTypeItem {
  id: number;
  name: string;
  thumbnail: string;
  imageName: string;
  description: string;
  recursiveChildren: Array<SeriesType>;
  slug: string;
}

export interface PageProductType {
  title: string;
  pageMetaData: {
    bannerTitleDescriptionImage0: {
      heading: string;
      description: string;
      bgImage: string;
      mobileBgImage: string;
      tabletBgImage: string;
    };
    productsSectionApplications0: {
      heading: string;
    };
  };
}

export interface PageProductResponse {
  data: PageProductType;
  success: boolean;
  message: string;
}

export interface ProductTypeResponse {
  data: Array<ProductTypeItem>;
  success: boolean;
  message: string;
  loaded: boolean;
}

export interface ProductTypeSlugResponse {
  data: { data: ProductTypeType; metaData: MetaDataType; breadcrumbs: BreadcrumbsType };
  success: boolean;
  message: string;
}

export interface ProductSeriesResponse {
  message: string;
  success: boolean;
  data: Array<SeriesType>;
  meta: {
    limit: number;
    page: number;
    total: number;
    totalPages: number;
  };
}

type ProductSeriesListRequest = {
  page?: number;
  limit?: number;
  keyword?: string;
  active_cat?: true;
};

export interface SeriesSlugResponse {
  success: boolean;
  message: string;
  data: {
    data: SeriesType;
    metaData: MetaDataType;
    breadcrumbs: BreadcrumbsType;
    success?: boolean;
  };
}

export interface SeriesIdResponse {
  success: boolean;
  message: string;
  data: SeriesType;
}

export interface PostProductContactType {
  fullName: string;
  phone: string;
  email: string;
  content: string;
  productTypeId: number;
}

export interface PostProductContactResponse {
  message: string;
  success: boolean;
}

export const getProductTypeList = async (): Promise<ProductTypeResponse> => {
  const { data } = await http.get(`/product-types`);
  return data;
};

export const getProductTypeSlug = async (slug: string): Promise<ProductTypeSlugResponse> => {
  const { data } = await http.get(`/product-types/slug/${slug}`, { shouldCheckErrorPage: true });
  return data;
};

export const getProductSeriesList = async (params: ProductSeriesListRequest): Promise<ProductSeriesResponse> => {
  const res = await http.get('/series/search', {
    params,
  });
  return res.data;
};

export const getSeriesSlug = async (slug: string): Promise<SeriesSlugResponse> => {
  const { data } = await http.get(`/series/slug/${slug}`, { shouldCheckErrorPage: true });
  return data;
};

export const getSeriesId = async (id: number, btu?: number): Promise<SeriesSlugResponse> => {
  const { data } = await http.get(`/series/${id}${typeof btu !== 'undefined' ? `?btu=${btu}` : ''}`);
  return data;
};

export const getSeriesChildId = async (id: number): Promise<SeriesIdResponse> => {
  const { data } = await http.get(`/series/child/${id}`);
  return data;
};

export const postProductContact = async ({
  fullName,
  email,
  phone,
  content,
  productTypeId,
}: PostProductContactType): Promise<PostProductContactResponse> => {
  const { data } = await http.post('/product-types/contacts', {
    full_name: fullName,
    phone,
    email,
    content,
    product_type_id: productTypeId,
  });
  return data;
};

export const getSeriesToPreview = async (code: string, params?: { btu?: string }): Promise<SeriesSlugResponse> => {
  const { data } = await http.get(`/series/preview/${code}`, { params });
  return data;
};
