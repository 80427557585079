import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import Swiper from 'swiper';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { mapModifiers } from 'lib/component';
import { Container } from 'components/organisms/grid';
import { Text } from 'components/atoms/text';
import { Toggleicon } from 'components/atoms/toggleicon';

type TabsModifiers = 'typenews' | 'singleMode';

interface TabsProps {
  modifiers?: TabsModifiers | TabsModifiers[];
  defaultIndex?: number;
  /* NOTE:
   *  This is only used when the component's behavior will sync with the browser.
   *  When you passed the synchronousBrowser property into this component. Please do NOT forget to give the link (Tab component) for sync-browser
   */
  synchronousBrowser?: boolean;
}

interface TabsContext {
  currentIndex: number;
  setIndex: (index: number) => void;
  synchronousBrowser?: boolean;
}

const TabsContext = React.createContext<TabsContext | null>(null);

export const Tabs: React.FC<TabsProps> = ({ modifiers = [], ...props }) => {
  const [currentIndex, setIndex] = useState<number>(props.defaultIndex as number);

  useEffect(() => {
    setIndex(props.defaultIndex as number);
  }, [props.defaultIndex]);

  return (
    <div className={mapModifiers('o-tabs', modifiers)}>
      <TabsContext.Provider
        value={{
          currentIndex,
          setIndex: (index: number) => setIndex(index),
          synchronousBrowser: props.synchronousBrowser,
        }}
      >
        {modifiers.includes('singleMode')
          ? props.children
          : React.Children.map(props.children, (child, index) =>
              index === 0 || index === currentIndex ? child : null
            )}
      </TabsContext.Provider>
    </div>
  );
};

Tabs.defaultProps = {
  defaultIndex: 1,
};

interface TabListProps {
  noDirection?: boolean;
  autoWidth?: boolean;
  centered?: boolean;
  type?: 'button';
}

export const TabList: React.FC<TabListProps> = props => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const rootEl = rootRef.current;
    const containerEl = containerRef.current;
    if (!containerEl || !rootEl) return undefined;
    const swiper = new Swiper(containerEl, {
      spaceBetween: 12,
      slidesPerView: props.autoWidth ? 'auto' : 1.5,
      breakpoints: {
        576: {
          spaceBetween: 12,
          slidesPerView: props.autoWidth ? 'auto' : 2.5,
        },
        992: {
          spaceBetween: 12,
          slidesPerView: props.autoWidth ? 'auto' : 4,
        },
        1200: {
          spaceBetween: 12,
          slidesPerView: props.autoWidth ? 'auto' : 5.5,
        },
      },
      navigation: {
        nextEl: rootEl.querySelector('.swiper-button-next') as HTMLElement,
        prevEl: rootEl.querySelector('.swiper-button-prev') as HTMLElement,
      },
      setWrapperSize: props.centered,
    });

    return () => {
      swiper.destroy(true, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={mapModifiers(
        'o-tabs_list',
        props.type || '',
        props.centered ? 'centered' : undefined,
        props.noDirection && 'nodirection',
        props.autoWidth ? 'autowidth' : undefined
      )}
      ref={rootRef}
    >
      <Container className={props.type === 'button' ? 'container-tabs' : ''}>
        <div className="o-tabs_listcontainer">
          <div className="swiper-container" ref={containerRef}>
            <div className="swiper-wrapper">
              {React.Children.map(props.children, (child, index) => (
                <div className="swiper-slide" key={index}>
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {React.cloneElement(child as any, { ...props, tabIndex: props.type === 'button' ? index : index + 1 })}
                </div>
              ))}
            </div>
          </div>
          {!props.noDirection && (
            <>
              <div className="swiper-button-next" />
              <div className="swiper-button-prev" />
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

interface TabProps {
  icon?: string;
  iconActive?: string;
  title?: string;
  onSelect?: () => void;
  tabIndex?: number;
  link?: string;
  target?: string;
  type?: 'button';
}

export const Tab: React.FC<TabProps> = props => {
  const location = useLocation();
  const history = useHistory();
  const TabsContextValue = useContext(TabsContext);

  const handleClickTab = useCallback((e) => {
    e.preventDefault();
    if (props.tabIndex !== TabsContextValue?.currentIndex) {
      if (props.link?.includes('http')) {
        window.open(props.link, props.target);
      } else {
        TabsContextValue?.setIndex(props.tabIndex as number);
        props.onSelect && props.onSelect();

        if (TabsContextValue?.synchronousBrowser && props.link) {
          history.push(props.link);
        }
      }
    }
  }, [TabsContextValue, history, props]);

  useEffect(() => {
    if (
      TabsContextValue?.synchronousBrowser &&
      location.pathname === props.link &&
      TabsContextValue?.currentIndex !== props.tabIndex
    ) {
      TabsContextValue?.setIndex(props.tabIndex as number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (!TabsContextValue) return null;

  return (
    <Link
      to={props.link || '#'}
      className={mapModifiers(
        'o-tabs_tab',
        props.tabIndex === TabsContextValue.currentIndex && 'actived',
        props.type || '',
        props.type && props.tabIndex === TabsContextValue.currentIndex && `${props.type}-actived`
      )}
      onClick={handleClickTab}
    >
      {props.icon && props.iconActive && (
        <Toggleicon
          icon={props.icon}
          iconActive={props.iconActive}
          modifiers={props.type === 'button' ? 's-large' : 'medium'}
        />
      )}
      <Text modifiers={[props.type === 'button' ? 'normal' : 'bold', props.type === 'button'? 'brownishgrey-op-05' : 'brownishgrey']}>{props.title}</Text>
    </Link>
  );
};

interface TabPanelProps {
  modifiers?: ['spacing-40' | 'spacing-10'];
}

export const TabPanel: React.FC<TabPanelProps> = props => (
  <div className={mapModifiers('o-tabs_tabpanel', props.modifiers)}>{props.children}</div>
);
