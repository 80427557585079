/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-console */
/* eslint-disable react/forbid-elements */
import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/marutto/img/common/logo.svg';
import backToTop from 'assets/marutto/img/common/btn-top.svg';
import contactClose from 'assets/marutto/img/common/btn_contact_close.svg';
import iconContact from 'assets/marutto/img/common/icon_contact.svg';
import { MARUTTO_PATHS } from 'utils/constants';
import { getMaruttoPath } from 'lib/pathes';
import { Language, LanguageItem } from 'components/atoms/language';
import { getCurrentLanguage } from 'i18n';
import { action } from 'store';
import { FOOTER_LOADING } from 'store/types/footer';
import { changeLanguage } from 'lib/history';
import { useTranslation } from 'react-i18next';
import { FloatingContact } from 'components/organisms/FloatingContact';

export const MaruttoLayout: React.FC = props => {
  const { i18n } = useTranslation();
  const onChangeLangue = useCallback(
    (lng: string) => {
      if (lng !== getCurrentLanguage()) {
        changeLanguage(lng);
      }
      action({ type: FOOTER_LOADING });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCurrentLanguage()]
  );

  useLayoutEffect(() => {
    const contact = document.getElementById('contact');
    const contactClose = document.getElementById('contactClose');
    const footer = document.querySelector('footer');
    function handleScroll() {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollPosition = window.innerHeight + window.scrollY;

      if (contact && footer) {
        // const mvHeight = 0;
        const footerHeight = footer.offsetHeight;
        if (window.scrollY > 0) {
          contact.classList.add('is-show');
        } else {
          contact.classList.remove('is-show');
        }

        if (scrollHeight - scrollPosition <= footerHeight) {
          contact.classList.add('is-stop');
        } else {
          contact.classList.remove('is-stop');
        }
      }
    }

    if (contactClose && contact) {
      contactClose.onclick = function () {
        contact.classList.add('is-hide');
        window.removeEventListener('scroll', handleScroll);
      };
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const toggleHam = () => {
      {
        const hamburger = document.querySelector('#headerTrigger');
        const headNav = document.querySelector('#headerNav');

        if (hamburger && headNav) {
          hamburger.addEventListener('click', () => {
            console.log(123);

            if (hamburger.classList.contains('is-active')) {
              hamburger.classList.remove('is-active');
              headNav.classList.remove('is-open');
            } else {
              hamburger.classList.add('is-active');
              headNav.classList.add('is-open');
            }
          });

          headNav.addEventListener('click', () => {
            hamburger.classList.remove('is-active');
            headNav.classList.remove('is-open');
          });
        }
      }
    };
    toggleHam();
  }, []);

  return (
    <>
      <header className="marutto-header">
        <div className="marutto-header__logo">
          <a href="https://www.daikin.com.vn">
            <img src={logo} alt="DAIKIN" />
          </a>
        </div>
        <nav className="marutto-header__nav" id="headerNav">
          <ul className="marutto-header__nav-list">
            <li>
              <Link
                to={{
                  pathname: getMaruttoPath(),
                  state: {
                    scrollId: 'mv',
                  },
                }}
              >
                {i18n.language === 'vi' ? 'Trang chủ' : 'Top'}
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: getMaruttoPath(),
                  state: {
                    scrollId: 'what-is-marutto',
                  },
                }}
              >
                {i18n.language === 'vi' ? 'Marutto là gì?' : 'What is Marutto'}
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: getMaruttoPath(),
                  state: {
                    scrollId: 'features',
                  },
                }}
              >
                {i18n.language === 'vi' ? 'Tính năng' : 'Features'}
              </Link>
            </li>
            <li>
              <Link to={getMaruttoPath(MARUTTO_PATHS.DOCUMENT)}>
                {i18n.language === 'vi' ? 'Tài liệu' : 'Documents'}
              </Link>
            </li>
            <li>
              <Link to={getMaruttoPath(MARUTTO_PATHS.INDUSTRY)} replace>
                {i18n.language === 'vi' ? 'Giải pháp cho các ứng dụng' : 'Industry Solutions'}
              </Link>
            </li>
            {/* <li>
              <Link to={getMaruttoPath(MARUTTO_PATHS.CASE_STUDIES)}>
                {i18n.language === 'vi' ? 'Công trình tiêu biểu' : 'Case Studies'}
              </Link>
            </li>
            <li>
              <Link to={getMaruttoPath(MARUTTO_PATHS.CONTACT_US)}>
                {i18n.language === 'vi' ? 'Liên hệ' : 'Contact Us'}
              </Link>
            </li> */}
            <li className="marutto-header__wraplang">
              <Language langActived={getCurrentLanguage() || ''}>
                <LanguageItem
                  title="VI"
                  value="vi"
                  active={getCurrentLanguage() === 'vi'}
                  onClick={() => onChangeLangue('vi')}
                />
                <LanguageItem
                  title="EN"
                  value="en"
                  active={getCurrentLanguage() === 'en'}
                  onClick={() => onChangeLangue('en')}
                />
              </Language>
            </li>
          </ul>
        </nav>
        <div className="marutto-header__trigger" id="headerTrigger">
          <span />
          <span />
          <span />
        </div>
        <div className="marutto-header__bg" />
      </header>
      <main>
        {props.children}
        <>
          {/* btns */}
          <div className="btn-contact" id="contact">
            <Link className="btn-contact__link" to={getMaruttoPath(MARUTTO_PATHS.CONTACT_US)}>
              <figure>
                <img src={iconContact} width={24} height={18} alt="" />
                <figcaption>{i18n.language === 'vi' ? 'Liên hệ' : 'Contact Us'}</figcaption>
              </figure>
            </Link>
            <div className="btn-contact__close" id="contactClose">
              <img src={contactClose} width={20} height={20} alt="Close" />
            </div>
          </div>
          <div
            className="btn-top"
            style={{ cursor: 'pointer' }}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <span>
              <img src={backToTop} width={42} height={42} alt="Back to top" />
            </span>
          </div>
        </>
      </main>
      <footer>
        <FloatingContact isMaruttoFlow />
        {/* <div className="inner marutto-footer__inner">
          <p className="marutto-footer__copyright">Copyright © 2024 Daikin Holdings Singapore Pte Ltd.</p>
          <a href="https://www.daikin-solutions.com/" target="_blank" className="marutto-footer__link">
            Daikin Regional Portal
          </a>
        </div> */}
        {/* /inner */}
      </footer>
    </>
  );
};
