import { getPath } from 'lib/pathes';

export const CONSTANT_LANGUAGE_LIST = ['vi', 'en'];

export const LOCAL_STORAGE = {
  LANGUAGE: 'DAIKIN_Language',
  ACCESS_TOKEN: 'DAIKIN_AccessToken',
};

export const MARUTTO_PATHS = {
  HOME: `marutto`,
  DOCUMENT: `documentlist`,
  INDUSTRY: `industry-solutions`,
  BUILDING: `industry-solutions/buildings-and-offices`,
  SHOPS: `industry-solutions/shops-and-commercial-facilities`,
  HOSPITALS: `industry-solutions/hospitals-and-welfare-facilities`,
  HOTEL: `industry-solutions/hotel`,
  EDUCATIONAL: `industry-solutions/educational-institutions`,
  FACTORY: `industry-solutions/factory`,
  LOGISTICS: `industry-solutions/logistics-warehouse`,
  CASE_STUDIES: `case-studies`,
  CONTACT_US: `contact-us`,
};
