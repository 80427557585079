import React from 'react';
import { mapModifiers } from 'lib/component';

interface Props {
  modifiers?: 'medium' | 'large' | 's-large' | 'm-large';
  icon: string;
  iconActive: string;
}

export const Toggleicon: React.FC<Props> = props => {
  return (
    <div className={mapModifiers('a-toggleicon', props.modifiers)}>
      <span
        className="a-toggleicon_icon"
        style={{ background: `url(${props.icon}) center center/contain no-repeat` }}
      />
      <span
        className="a-toggleicon_icon"
        style={{ background: `url(${props.iconActive}) center center/contain no-repeat` }}
      />
    </div>
  );
};
