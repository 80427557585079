import { Image } from 'components/atoms/image';
import { mapModifiers } from 'lib/component';
import React from 'react';

import { Icon } from 'components/atoms/icon';
import apiPathes from 'constants/api-pathes';
import { getMaruttoPath, getPath } from 'lib/pathes';
import useDidMount from 'lib/usedidmount';
import { Link } from 'react-router-dom';
import { action, useAppSelector } from 'store';
import { GET_FLOATING_BANNER, SET_TOGGLE_FLOATING_BANNER } from 'store/types/floatingBanner';
import { MARUTTO_PATHS } from 'utils/constants';

export const FloatingContact: React.FC<{ isMaruttoFlow?: boolean }> = ({ isMaruttoFlow }) => {
  const {
    floatingBanner: { loaded, data, isOpen },
  } = useAppSelector(state => ({ floatingBanner: state.floatingBanner }));

  useDidMount(() => !loaded && action({ type: GET_FLOATING_BANNER }));

  return data ? (
    <>
      <div className={mapModifiers('o-floatingContact', isOpen ? 'open' : 'close')}>
        {!isMaruttoFlow ? (
          <div className="o-floatingContact_collapseArrow" onClick={() => action({ type: SET_TOGGLE_FLOATING_BANNER })}>
            <Icon iconName="collapse-arrow" modifiers="small" />
          </div>
        ) : null}
        <div className="o-floatingContact_body">
          <div className="o-floatingContact_body_messageIcon">
            <Image
              alt="Message"
              src={`${apiPathes.STORAGE}${data?.image || ''}`}
              aspectRatio="1x1"
              modifiers={['transparent']}
            />
          </div>
          <div
            className="o-floatingContact_body_contactUsText"
            dangerouslySetInnerHTML={{ __html: data?.content || '' }}
          />
          <div className="o-floatingContact_body_buttons">
            <a
              href={`tel:${!isMaruttoFlow ? data?.button.contact.text : '18006777'}`}
              className={mapModifiers('o-floatingContact_body_button', 'phone')}
            >
              <div className="o-floatingContact_body_item">
                <Image
                  alt="contact"
                  src={`${apiPathes.STORAGE}${data?.button.contact.icon || ''}`}
                  aspectRatio="1x1"
                  modifiers={['transparent']}
                />
                <span>{!isMaruttoFlow ? data?.button.contact.text : '18006777'}</span>
              </div>
            </a>
            <Link
              to={isMaruttoFlow ? getMaruttoPath(MARUTTO_PATHS.CONTACT_US) : getPath('CT')}
              className={mapModifiers('o-floatingContact_body_button', 'advice')}
            >
              <div className="o-floatingContact_body_item">
                <Image
                  alt="Consult"
                  src={`${apiPathes.STORAGE}${data?.button.consult.icon || ''}`}
                  aspectRatio="1x1"
                  modifiers={['transparent']}
                />
                <span>{data?.button.consult.text}</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="o-floatingContact_mobile">
        <a href={`tel:${data?.button.contact.text}`} className="o-floatingContact_mobile_button">
          <div className={mapModifiers('o-floatingContact_mobile_item', 'phone')}>
            <Image
              alt="contact"
              src={`${apiPathes.STORAGE}${data?.button.contact.icon || ''}`}
              aspectRatio="1x1"
              modifiers={['transparent']}
            />
          </div>
        </a>
        <Link
          to={isMaruttoFlow ? getMaruttoPath(MARUTTO_PATHS.CONTACT_US) : getPath('CT')}
          className="o-floatingContact_mobile_button"
        >
          <div className={mapModifiers('o-floatingContact_mobile_item', 'mail')}>
            <Image
              alt="Consult"
              src={`${apiPathes.STORAGE}${data?.button.consult.icon || ''}`}
              aspectRatio="1x1"
              modifiers={['transparent']}
            />
          </div>
        </Link>
      </div>
    </>
  ) : null;
};
