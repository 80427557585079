import { Loading } from 'components/atoms/loading';
import { getPathes } from 'lib/pathes';
import React, { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { useAppSelector } from 'store';
import { MARUTTO_PATHS } from 'utils/constants';

const MaruttoDocumentList = lazy(() => import('pages/marutto/maruttoDocumentList'));
const MaruttoCaseStudies = lazy(() => import('pages/marutto/maruttoCaseStudies'));
const MaruttoContactUs = lazy(() => import('pages/marutto/maruttoContactUs'));
const MaruttoIndustrySolutions = lazy(() => import('pages/marutto/maruttoIndustrySolutions'));
const BuildingAndOffices = lazy(() => import('pages/marutto/maruttoIndustrySolutions/buildingAndOffices'));
const EducationalInstitutions = lazy(() => import('pages/marutto/maruttoIndustrySolutions/educationalInstitutions'));
const Factory = lazy(() => import('pages/marutto/maruttoIndustrySolutions/factory'));
const HospitalsAndWelfareFacilities = lazy(() =>
  import('pages/marutto/maruttoIndustrySolutions/hospitalsAndWelfareFacilities')
);
const Hotel = lazy(() => import('pages/marutto/maruttoIndustrySolutions/hotel'));
const LogisticsAndWarehouse = lazy(() => import('pages/marutto/maruttoIndustrySolutions/logisticsAndWarehouse'));
const ShopAndCommercialFacilities = lazy(() =>
  import('pages/marutto/maruttoIndustrySolutions/shopAndCommercialFacilities')
);
const Home = lazy(() => import('pages/marutto'));

const ProtectedRoute = lazy(() => import('lib/protected-route'));
const ProductComparison = lazy(() => import('lib/product-comparison'));
const CaseStudyDetailPage = lazy(() => import('pages/discovery/casestudy/detail'));
const NewsPage = lazy(() => import('pages/discovery/news'));
const GuidesPage = lazy(() => import('pages/discovery/guides'));
const NewsDetailPage = lazy(() => import('pages/discovery/news/detail'));
const GuidesDetailPage = lazy(() => import('pages/discovery/guides/detail'));
const SolutionDetailPage = lazy(() => import('pages/solution/detail'));
const SolutionPage = lazy(() => import('pages/solution'));
const ProductListPage = lazy(() => import('pages/product/list'));
const ProductDetailPage = lazy(() => import('pages/product/list/detail'));
const ComparisontPage = lazy(() => import('pages/comparison/index'));
// const AirPurifierPage = lazy(() => import('pages/airPurifier/index'));
// const SiteMapPage = lazy(() => import('pages/sitemap'));

function Routes() {
  const { isNotFound } = useAppSelector(state => state.common);
  return (
    <Suspense fallback={<Loading />}>
      <div style={{ minHeight: isNotFound ? 'auto' : 'calc(100vh - 144px)' }}>
        {/* <div style={{ display: isNotFound ? 'block' : 'none' }}>
          <Notfound />
        </div> */}
        {/* <Route path={getPathes('AIRPURIFIER')} exact component={AirPurifierPage} /> */}
        <Route path={getPathes('CMP')} exact component={ComparisontPage} />
        {/* <Route path={getPath('SITEMAP')} exact component={SiteMapPage} /> */}
        <Route path={['/en/:slugPage?', '/:slugPage?']} exact component={ProtectedRoute} />
        <Route
          path={getPathes('DCVCS').map(pathValue => `${pathValue}/:slugCasestudy`)}
          exact
          component={CaseStudyDetailPage}
        />
        <Route path={getPathes('DCVNC').map(pathValue => `${pathValue}/:slugCategory`)} exact component={NewsPage} />
        <Route path={getPathes('DCVN').map(pathValue => `${pathValue}/:slugNew`)} exact component={NewsDetailPage} />
        <Route path={getPathes('DCVGC').map(pathValue => `${pathValue}/:slugCategory`)} exact component={GuidesPage} />
        <Route
          path={getPathes('DCVG').map(pathValue => `${pathValue}/:slugGuide`)}
          exact
          component={GuidesDetailPage}
        />
        <Route
          path={getPathes('SLT').map(pathValue => `${pathValue}/:slugSolution`)}
          exact
          component={SolutionDetailPage}
        />
        <Route
          path={getPathes('CategorySLT').map(pathValue => `${pathValue}/:slugCategorySolution`)}
          exact
          component={SolutionPage}
        />
        <Route
          path={getPathes('PRD').map(pathValue => `${pathValue}/:slugType`)}
          exact
          render={() => <ProductComparison page={<ProductListPage />} />}
        />
        <Route
          path={getPathes('PRD').map(pathValue => `${pathValue}/:slugType/:slugDetail`)}
          exact
          render={() => <ProductComparison page={<ProductDetailPage />} />}
        />
        <Route
          path={getPathes('PRD').map(pathValue => `${pathValue}/:slugType/:slugDetail/:slugSeries`)}
          exact
          render={() => <ProductComparison page={<ProductDetailPage />} />}
        />
        {/* <Route path="*" component={OtherNotfound} /> */}
      </div>
    </Suspense>
  );
}
export const maruttoRoutes = [
  {
    path: MARUTTO_PATHS.HOME,
    element: <Home />,
  },
  {
    path: `${MARUTTO_PATHS.HOME}/${MARUTTO_PATHS.DOCUMENT}`,
    element: <MaruttoDocumentList />,
  },
  {
    path: `${MARUTTO_PATHS.HOME}/${MARUTTO_PATHS.INDUSTRY}`,
    element: <MaruttoIndustrySolutions />,
  },
  {
    path: `${MARUTTO_PATHS.HOME}/${MARUTTO_PATHS.CONTACT_US}`,
    element: <MaruttoContactUs />,
  },
  {
    path: `${MARUTTO_PATHS.HOME}/${MARUTTO_PATHS.CASE_STUDIES}`,
    element: <MaruttoCaseStudies />,
  },
  {
    path: `${MARUTTO_PATHS.HOME}/${MARUTTO_PATHS.BUILDING}`,
    element: <BuildingAndOffices />,
  },
  {
    path: `${MARUTTO_PATHS.HOME}/${MARUTTO_PATHS.SHOPS}`,
    element: <ShopAndCommercialFacilities />,
  },
  {
    path: `${MARUTTO_PATHS.HOME}/${MARUTTO_PATHS.HOSPITALS}`,
    element: <HospitalsAndWelfareFacilities />,
  },
  {
    path: `${MARUTTO_PATHS.HOME}/${MARUTTO_PATHS.HOTEL}`,
    element: <Hotel />,
  },
  {
    path: `${MARUTTO_PATHS.HOME}/${MARUTTO_PATHS.FACTORY}`,
    element: <Factory />,
  },
  {
    path: `${MARUTTO_PATHS.HOME}/${MARUTTO_PATHS.LOGISTICS}`,
    element: <LogisticsAndWarehouse />,
  },
  {
    path: `${MARUTTO_PATHS.HOME}/${MARUTTO_PATHS.EDUCATIONAL}`,
    element: <EducationalInstitutions />,
  },
];

export default Routes;
